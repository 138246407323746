import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import LandingPage from "./LandingPage";
import QuizWindow from "./Quiz";
import Registration from "./Registration";
import ThankYou from "./ThankYou";
import LeaderBoard from "./LeaderBoard";

export default function RoutesWithAnimation() {
  const location = useLocation();
  useEffect(() => {
    console.log(import.meta.env.VITE_SERVER_URL);
  }, []);
  return (
    <Routes location={location} key={location.key}>
      <Route path="/" element={<LandingPage />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/quiz" element={<QuizWindow />} />
      <Route path="/thankyou" element={<ThankYou />} />
      <Route path="/leaderboard" element={<LeaderBoard />} />
    </Routes>
  );
}
