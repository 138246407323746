import { AnimatePresence } from "framer-motion";
import Footer from "./components/Footer.jsx";
import Navbar from "./components/Navbar.jsx";
import RoutesWithAnimation from "./components/RoutesWithAnimation.jsx";
import LocationProvider from "./components/LocationProvider.jsx";

function App() {
  return (
    <AnimatePresence>
      <Navbar />
      <div className="container">
        <LocationProvider>
          <RoutesWithAnimation />
        </LocationProvider>
      </div>
      <Footer />
    </AnimatePresence>
  );
}

export default App;
