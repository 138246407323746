 import { Link } from "react-router-dom";
 import quizlogo from "../assets/quiz_logo.png";

const LandingPage = () => {
  return (
    <div className="body-wrapper home-page">
      {/* <h2>X Factor</h2> */}
      <img src={quizlogo} alt="Quiz Logo" id="logo" />
      <p>
        Calling all travel fanatics! Ready for an adventure quiz? Grab your
        friends, take on the challenge, and score amazing rewards. Join us on
        the fun journey!
      </p>

      <Link to="/registration">
        <button className="btn-cta">Participate Now</button>
      </Link>
    </div>
  );
};

export default LandingPage;
