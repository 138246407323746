import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useElapsedTime } from "use-elapsed-time";

import { routeVariants } from "../../utils";
import QuizPanel from "./QuizPanel";
import Spinner from "../Spinner";
import ImageQuizPanel from "./ImageQuizPanel";
import convertSeconds from "./TimeUtil";


const QuizWindow = () => {
  const navigate = useNavigate();

  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [selection, setSelection] = useState({});
  const [errors, setErrors] = useState("");
  const [loading, setIsloading] = useState(false);
  const [questions, setQuestions] = useState({});
  const [checked, setChecked] = useState(false);
  const [startTime, setStartTime] = useState(false);
    const [current, setCurrent] = useState({ question: {}, index: 0 });



  const { elapsedTime } = useElapsedTime({ isPlaying: startTime });
 
 

  // function toTime(seconds) {
  //   const date = new Date(null);
  //   date.setSeconds(seconds);
  //   return date.toISOString().substr(11, 8);
  // }

  useEffect(() => {
    if(questions?.length > 0) {
      setCurrent((prevState) => ({...prevState, question: questions[0] }));
    }
  },[questions])

  async function getQuiz() {
    setIsloading(true);
    setErrors("");

    try {
      const request = await fetch(
        import.meta.env.VITE_SERVER_URL + "api/quiz/quiz123",
        {
          headers: {
            "api-key": import.meta.env.VITE_API_KEY
          }
        }
      );

      const statusCode = request.status;
        const resp = await request.json();
      
      if (statusCode === 200) {
        setQuestions(resp.quiz);
       
        setStartTime(true);
        setIsloading(false);
      }
      if(statusCode === 500) {
         setErrors("Error Fetching Quiz");
      }
    } catch (error) {
      setErrors("Error Fetching Quiz");
    } finally {
      // setIsloading(false);
    }
  }
  async function handleSubmit() {
    setErrors("");
    setIsloading(true)
    const userId = localStorage.getItem("user");
    const payload = {
      answers: answeredQuestions,
      user: userId,
      time: elapsedTime
    };
    try {
      const request = await fetch(
        `${import.meta.env.VITE_SERVER_URL}api/submit-answers`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "api-key": import.meta.env.VITE_API_KEY
          },
          body: JSON.stringify(payload)
        }
      );

      const statusCode = request.status;

      if (statusCode === 201) {
        localStorage.setItem("completed", true);
        navigate("/thankyou");
      } else {
        setErrors("Error Submitting Answer");
      }
    } catch (error) {
      setErrors("Error Submitting Answer");
    } finally{
      setIsloading(false);
    }
  }


  //preloading next images
  useEffect(() => {
    if (
      current.index < questions.length - 1 &&
      questions[current.index + 1].image_options === true
    ) {
      for (const option of questions[current.index + 1].options) {
        const nextImage = new Image();
        nextImage.src =
          import.meta.env.VITE_SERVER_URL + "image" + option.option_image;
      }
    }
  }, [current]);

 
  function handleNext() { 
    
    setAnsweredQuestions((prev) => [...prev, selection]);
    setSelection({})
    current.index + 1 < totalQuestions &&
      setCurrent((prevState) => ({
        ...prevState,
        question: questions[prevState.index + 1],
        index: prevState.index + 1
      }));
    // current.index + 1 >= totalQuestions && navigate("/thankyou");
  }

  function handleSelection(value) {
    setSelection(value);
  }


  function renderQuizPanel() {
    if (!current.question?.image_options) {
       return (
         <QuizPanel
           question={current.question}
           onSelect={(value) => handleSelection(value)}
           selection={selection}
           key={current.question.id}
         />
       );
    }
    return (
      <ImageQuizPanel
        question={current.question}
        onSelect={(value) => handleSelection(value)}
        selection={selection}
        key={current.question.id}
      />
    );
  }

  async function hasCompleted() {
    const userId = localStorage.getItem("user");
    if(!userId) {
      navigate("/")
    }
    try {
      const request = await fetch(
        import.meta.env.VITE_SERVER_URL + "api/hascompleted/" + userId,
        {
          headers: {
            "api-key": import.meta.env.VITE_API_KEY
          }
        }
      );   
     
      const resp = await request.json();
      
      if (request.status !== 200 && request.status !== 404) {
        throw new Error("server error");
      }

      if(request.status === 404) {
        navigate("/registration");
      }
      
      if (resp.completed === true ) {
        navigate("/thankyou");
      } 
      if (request.status === 200) {       
        getQuiz();
      }
    } catch (error) {
      
      setErrors("Error Fetching Quiz");
     
    } finally {
      setIsloading(false);
      setChecked(false)
    }
  }
  useEffect(() => {

    if(!checked ) {
         hasCompleted();
    }
    

    
   
  }, [checked]);
 

  const totalQuestions = 5;
 
  return (
    <motion.div
      variants={routeVariants}
      initial="initial"
      animate="final"
      className="quiz-page"
    >
      <div style={{position: "sticky", top: 0, background: "#723C78", paddingTop: "20px"}}>
        <div className="range-wrapper">
          <div className="quiz-progress">
            <div
              className="progress-bar"
              style={{
                width:
                  ((answeredQuestions.length + 1) / totalQuestions) * 100 + "% "
              }}
            ></div>
          </div>
          <div className="inner">
            <div>
              {answeredQuestions.length + 1}/{totalQuestions}
            </div>
            <div className="timer">
              Elapsed Time <span> {convertSeconds(elapsedTime)}</span>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
      {!loading &&
        questions &&
        Object.keys(questions).length &&
        renderQuizPanel()}

      {answeredQuestions.length + 1 !== totalQuestions && (
        <div className="btn-wrapper">
          <button
            className="btn-cta"
            onClick={handleNext}
            disabled={!Object.keys(selection).length}
          >
            Next
          </button>
        </div>
      )}
      {errors.length > 0 && <div className="error-tip">{errors}</div>}
      {answeredQuestions.length + 1 === totalQuestions && (
        <div className="btn-wrapper">
          <button
            className="btn-cta"
            onClick={handleSubmit}
            disabled={!Object.keys(selection).length}
          >
            Submit
          </button>
        </div>
      )}
    </motion.div>
  );
};

export default QuizWindow;
