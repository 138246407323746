 import { Link } from "react-router-dom";
 import { motion } from "framer-motion";
import { routeVariants } from "../utils";
import ThankyouImage from "../assets/thankyou.png"

function ThankYou() {
 return (
   <motion.div
     variants={routeVariants}
     initial="initial"
     animate="final"
     className="body-wrapper home-page thankyou-page"
   >
     <img src={ThankyouImage} alt="Thank You" />
     <h3>Thank You For Participating</h3>
     <p>
       Our executives will be in touch if you are shortlisted. You can also
       check the leader board to see how you compare with other players. Good
       luck!
     </p>

     <Link to="/leaderboard">
       <button className="btn-cta">Leader Board</button>
     </Link>
   </motion.div>
 );
}

export default ThankYou

 

 