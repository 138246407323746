import notch from "../assets/notch.png";
import logo from "../assets/axios_logo.png";
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <header className="container">
      <img id="notch" src={notch} alt="notch" />
      <div className="desktop-nav">
        <Link to="/">
          <img id="logo" src={logo} alt="logo" />
        </Link>
        <ul>
          <li><Link to='/leaderboard'></Link></li>
        </ul>
      </div>
    </header>
  );
};

export default Navbar;
