import  {useState, useEffect} from "react";
import crwonImg2x from "../assets/leaderboard_crown_2x.png";
import quizlogo from "../assets/quiz_logo.png";
import { useNavigate } from "react-router-dom";
import { getInitials, ordinal_suffix_of } from "../utils";
import Spinner from "./Spinner";
import convertSeconds from "./Quiz/TimeUtil";

const LeaderBoard = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState("")
  const navigate = useNavigate()
  const totalQuestions = 5;
 
  async function getLeaderboard() {
    setIsLoading(true);
    setErrors("");
    const userId = localStorage.getItem("user");
    if(!userId) {
      navigate("/");
      return;
    }
    try {
      const request = await fetch(
        import.meta.env.VITE_SERVER_URL + "api/leaderboard",
        {
          headers: {
            "api-key": import.meta.env.VITE_API_KEY,
            user: userId
          }
        }
      );

      const statusCode = request.status;
      const resp = await request.json();
      
      if (statusCode === 200) {
        setLeaderboard(resp.leaderboard); 
      }
      if(statusCode === 500) {
         setErrors("Error Fetching Leaderboard");
      }
    } catch (error) {
      setErrors("Error Fetching Leaderboard");
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(()=> {
    getLeaderboard()
  },[])
 
  function renderLeaderboard() {
   return leaderboard.map((player, i) => (
     <div className="table-row" key={player.email}>
       <span className="col">
         <span>{ordinal_suffix_of(i + 1)}</span>
         <span>{player?.name}</span>
       </span>
       <span className="col">{player?.score}/11</span>
       <span className="col">{convertSeconds(player?.time)}</span>
     </div>
   ));
    
  }
   return (
     <>
       {isLoading && <Spinner />}
       {!isLoading && leaderboard.length > 0 && (
         <div className="leaderboard-page">
           <img src={quizlogo} alt="Quiz Logo" className="logo" id="logo" />
           <h5>Leaderboard</h5>

           <div className="crown-wrapper">
             <span className="card-badge">5/{totalQuestions}</span>
             <div className="mid">
               <div className="ring">
                 {getInitials(leaderboard[0]?.name || "")}
               </div>
               <div className="winner">{leaderboard[0]?.name}</div>
               <img src={crwonImg2x} className="crown_img" />
             </div>
             <span className="card-badge">
               {convertSeconds(leaderboard[0]?.time) || ""}
             </span>
           </div>

           <div className="winner-table">
             <div className="table-header">
               <span className="col">Participants</span>
               <span className="col">Score</span>
               <span className="col">Time Taken</span>
             </div>
             <div className="table-body">{renderLeaderboard()}</div>
           </div>
         </div>
       )}
       {errors && <div>{errors}</div>}
     </>
   );
};

export default LeaderBoard;
