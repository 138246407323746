import PropTypes from 'prop-types'; // ES6


function QuizPanel({ question, onSelect, selection }) {
  const { options, id } = question;
  return (
    <>
      <h5>{question?.question_text}</h5>
      <div className="quiz-wrapper">
        {options &&
          options.map((option) => (
            <div
              className={`quiz-item text-option ${
                selection?.answer_id === option?.option_id && "selected"
              }`}
              key={option?.option_id}
              onClick={() => onSelect({ id: id, answer_id: option?.option_id })}
            >
              <div>{option?.option_text}</div>
            </div>
          ))}
      </div>
    </>
  );
}

QuizPanel.propTypes = {
  question: PropTypes.object,
  onSelect: PropTypes.func,
  selection:  PropTypes.object,
};

export default QuizPanel

